import {BaseClass} from '../../classes/baseClass';
import Thumbnail from '../../classes/thumbnail';
import Search from '../../classes/search';
import ProductOption from './productOption';
import {formatUrl, mergeQueryParams} from '../../helpers';
import {dayjs} from '../../services/dayjs';
import {http} from '@teemill/common/services';

import {forIn} from 'lodash';

let allProducts = [];

/**
 * Product.js
 *
 * Defines the standard structure for a Product object
 *
 * @author Dan Evans <dan.e@teemill.com>
 */
export default class Product extends BaseClass {
  /**
   * @param {Number} id
   * @param {String} sizes
   * @param {String} name
   * @param {String} description
   * @param {String} metaTitle
   * @param {String} metaDescription
   * @param {Object} metaImage
   * @param {String} urlName
   * @param {Thumbnail[]} images
   * @param {Thumbnail[]} ugcImages
   * @param {Object} designs
   * @param {Object[]} applications
   * @param {ProductOption[]} options
   * @param {Number} price
   * @param {Number} salePrice
   * @param {Boolean} onSale
   * @param {Boolean} enabled
   * @param {Boolean} includeInProductFeed
   * @param {Number} minPrice
   * @param {Number} maxPrice
   * @param {Number} defaultPrice
   * @param {Number} vatRate
   * @param {Number} baseCost
   * @param {Number} baseCostWhite
   * @param {String} baseSku
   * @param {Array} collections
   * @param {Array} tags
   * @param {Object} manufacturer
   * @param {Number} keyPhraseCampaign
   * @param {Object[]} files
   * @param {Object[]} crossSellProducts
   * @param {Number} sizeChartId
   *
   */
  constructor({
    id,
    sku,
    name,
    description,
    metaTitle,
    metaDescription,
    metaImage,
    collections,
    tags,
    urlName,
    images,
    ugcImages,
    designs,
    applications,
    options,
    price,
    salePrice,
    onSale,
    enabled,
    gender,

    type,

    manufacturer,

    minPrice,
    maxPrice,
    defaultPrice,
    vatRate,
    baseCost,
    baseCostWhite,

    baseSku,
    baseProductId,

    approvalStatus,
    denialReason,

    isBundle = false,
    bundleItems = [],
    defaultColour,

    customiseIt = false,
    enableAddToCart = true,
    crossSellDesign = false,
    crossSellProducts = [],

    includeInProductFeed,

    isBaseProduct = false,
    isFProduct = false,
    isDiyFProduct = false,
    isEditableInStudio = false,
    magicModelsEnabled = false,

    orders = {},

    created = {},

    platen,
    printCosts,
    processing,

    keyPhraseCampaign,

    variants,

    files = [],
    mockupParams = {},
    priceMatrix = {},
    template,
    personalizable = false,
    personalizationTemplate,
    sizeChartId,
  } = {}) {
    super();

    this.id = id;
    this.sku = sku;
    this.name = name;
    this.description = description;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.metaImage = metaImage;
    this.collections = collections;
    this.tags = tags;
    this.urlName = urlName;
    this.type = type;

    this.manufacturer = manufacturer
      ? {
          name: manufacturer.name,
          urlName: manufacturer.urlName,
          avatar: manufacturer?.avatar,
          headerColor: manufacturer?.headerColor,
        }
      : null;

    this.images = images ? Thumbnail.map(images) : [];
    this.ugcImages = ugcImages ? Thumbnail.map(ugcImages) : [];
    this.options = options ? ProductOption.map(options) : [];
    this.designs = designs
      ? {
          front: designs.front,
          back: designs.back,
          left: designs.left,
          right: designs.right,
          // Temporary until per variant application edit exists
          // neck: designs.neck,
        }
      : null;

    this.applications = applications;

    this.enabled = enabled;
    this.gender = gender;
    this.price = price;
    this.salePrice = salePrice;
    this.onSale = onSale;

    /**
     * ? The sku of the base garment
     */
    this.baseSku = baseSku;
    this.baseProductId = baseProductId;

    /**
     * ? These are settings for royalties
     */
    this.minPrice = minPrice;
    this.maxPrice = maxPrice;
    this.defaultPrice = defaultPrice;
    this.vatRate = vatRate || 1;
    this.baseCost = baseCost;
    this.baseCostWhite = baseCostWhite;

    /**
     * ? These are settings for approval queue
     */
    this.approvalStatus = approvalStatus;
    this.denialReason = denialReason;

    /**
     * ? These are used by bundles and bundle items
     */
    this.isBundle = isBundle;
    this._bundleItems = [];
    this.bundleItems = bundleItems;
    this.defaultColour = defaultColour;

    this.isBaseProduct = isBaseProduct;

    this.customiseIt = customiseIt;
    this.enableAddToCart = enableAddToCart;
    this.inMyProductFeed = includeInProductFeed;
    this.crossSellDesign = crossSellDesign;
    this.crossSellProducts = crossSellProducts;
    this.isFProduct = isFProduct;
    this.isDiyFProduct = isDiyFProduct;
    this.isEditableInStudio = isEditableInStudio;
    this.orders = orders;

    this.created = {
      by: created.by,
      at: dayjs(created.at),
    };

    this.platen = platen;
    this.printCosts = printCosts;
    this.processing = processing;

    this.keyPhraseCampaign = keyPhraseCampaign;

    this.variants = variants;

    this.files = files;

    this.mockupParams = mockupParams;

    this.priceMatrix = priceMatrix;

    this.magicModelsEnabled = magicModelsEnabled;

    this.template = template;
    this.personalizationTemplate = personalizationTemplate;
    this.personalizable = personalizable || !!personalizationTemplate;
    this.sizeChartId = sizeChartId;
  }

  /**
   * Fetches product data from the server, from an array of IDs
   *
   * Uses the spread operator so can take as many IDs as needed
   *
   * @example
   * Product.fetch(123, 456, 789, ...)
   *  .then((products) => {
   *    this.products = products;
   *  })
   *
   * @param  {...Number} ids
   */
  static async fetch(...ids) {
    const {data} = await http.post(formatUrl('/omnis/v3/products/fetch/'), {
      product_ids: ids,
    });

    return this.map(data);
  }

  /**
   * Fetches product data for a base product from the server, from a sku
   *
   * @param  {String} sku
   */
  static async fetchBaseProduct(sku) {
    const {data} = await http.get(
      formatUrl('/omnis/v3/products/fetch-base-product/'),
      {
        params: {
          sku,
        },
      }
    );

    return new this(data);
  }

  /**
   * Finds the first image for this product
   *
   * @return {Thumbnail}
   *
   * @see Thumbnail
   */
  get image() {
    if (this.options && this.options.length) {
      return this.options[0].image;
    }

    return this.images[0];
  }

  get url() {
    return `/product/${this.urlName}/`;
  }

  /**
   * Gets all the colours of this product
   *
   * @return {Array}
   */
  get colours() {
    if (this.options) {
      return this.options.map(option => option?.name);
    }

    return [];
  }

  /**
   * Gets all the sizes of this product
   *
   * @return {Array}
   */
  get sizes() {
    if (this.options) {
      return this.options.flatMap(option => option?.sizes);
    }

    return [];
  }

  /**
   * Checks if the current product is a blank product (i.e. has no design file)
   *
   * @return {Boolean}
   */
  get isBlankProduct() {
    return this.sku.includes('BLANK');
  }

  get hasFrontPrint() {
    return !!this.designs?.front;
  }

  get hasBackPrint() {
    return !!this.designs?.back;
  }

  get printCount() {
    if (this._bundleItems.length) {
      let printCount = 0;
      this._bundleItems.forEach(item => {
        printCount += item.printCount;
      });
      return printCount;
    }

    if (this.hasFrontPrint && this.hasBackPrint) {
      return 2;
    } else if (this.hasFrontPrint || this.hasBackPrint) {
      return 1;
    }
    return 0;
  }

  get platenSize() {
    return this.platen?.code;
  }

  get bundleItems() {
    return allProducts.filter(p => this._bundleItems.includes(p.id));
  }

  set bundleItems(items) {
    this._bundleItems = items.map(item => new Product(item));
  }

  /**
   * Finds an image on this product relating to a specified colour
   *
   * @param {String} colour The colour to search for
   *
   * @return {Thumbnail} The thumbnail for the corresponding colour, or null if
   * this colour is not available on this product
   */
  getImageByColour(colour) {
    const matchingOptions = this.options.filter(
      option => option.name === colour
    );

    if (matchingOptions.length) {
      return matchingOptions[0].image;
    }

    return null;
  }

  /**
   * @name getRelated
   * @description Get a break down of related products.
   *
   * @return { group: Product[] }
   */
  getRelated(products, includeSearch) {
    allProducts = products.sort(
      (a, b) => a.orders.bestseller - b.orders.bestseller
    );

    const relatedProducts = {
      bundles: this.bundles,
      baseBundles: this.baseBundles,
      genderBundle: this.genderBundles,
      similarProduct: includeSearch ? this.similarProduct : [],
      sameBaseProduct: this.sameBaseProduct,
      // ? > 1 not 0 because it contains the current product.
      allProducts: this.sameGender.length > 1 ? this.sameGender : allProducts,
    };

    const uniqueIds = new Set([this.id]);

    forIn(relatedProducts, (group, name) => {
      const filteredProducts = group.filter(p => !uniqueIds.has(p.id));

      filteredProducts.forEach(product => {
        uniqueIds.add(product.id);
      });

      relatedProducts[name] = filteredProducts;
    });

    return relatedProducts;
  }

  /**
   * @name similarProduct
   * @description Returns a list of products in order of similarity to product title.
   *
   * @return Product[]
   */
  get similarProduct() {
    return new Search(this.sameGender, {name: 1}).query(
      this.name.split(' ').slice(0, -1).join(' '),
      0,
      false
    );
  }

  /**
   * @name sameBaseProduct
   * @description Returns a list of products that have the same base product.
   *
   * @return Product[]
   */
  get sameBaseProduct() {
    const ids = [
      this.baseProductId,
      ...this.bundleItems.map(p => p.baseProductId),
    ];

    return this.sameGender.filter(p => ids.includes(p.baseProductId));
  }

  /**
   * @name sameGender
   * @description Returns a list of products that have the same gender.
   *
   * @return Product[]
   */
  get sameGender() {
    const gender = this.gender;

    return allProducts.filter(
      p => p.gender === gender || p.gender === 'unisex'
    );
  }

  /**
   * @name genderBundles
   * @description List of bundles that have the same gender.
   *
   * @return Product[]
   */
  get genderBundles() {
    return this.sameGender.filter(p => p.isBundle);
  }

  /**
   * @name bundles
   * @description List of bundles that contain this product.
   *
   * @return Product[]
   */
  get bundles() {
    const ids = [this.id, ...this.bundleItems.map(p => p.id)];

    return this.genderBundles.filter(p =>
      p.bundleItems.some(i => ids.includes(i.id))
    );
  }

  /**
   * @name baseBundles
   * @description List of bundles that contain this products with this base product.
   *
   * @return Product[]
   */
  get baseBundles() {
    const ids = [
      this.baseProductId,
      ...this.bundleItems.map(p => p.baseProductId),
    ];

    return this.genderBundles.filter(p =>
      p.bundleItems.some(i => ids.includes(i.baseProductId))
    );
  }

  /**
   * Get a url string encoded product object that the push tool
   * can parse
   *
   * @returns {String}
   */
  get pushToolUrl() {
    return mergeQueryParams('/marketing-tools/notifications/builder', {
      title: this.name,
      body: this.description,
      related: `product|${this.id}`,
      href: this.url,
    });
  }

  isCustomProduct(excludeBlanks) {
    if (excludeBlanks && this.sku.indexOf('BLANK') !== -1) {
      return false;
    }

    if (
      this.sku.indexOf('GBro') === 0 ||
      this.sku.indexOf('STUSUB') === 0 ||
      this.sku.indexOf('FPD') === 0 ||
      this.sku.indexOf('API') === 0 ||
      this.sku.indexOf('CP') === 0
    ) {
      return true;
    }

    return false;
  }

  isPaperProduct() {
    return /printed.*paper/.test(this.type.code);
  }
}
