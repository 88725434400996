import {RouteRecordRaw} from 'vue-router';
import {sample, cloneDeep, uniqueId} from 'lodash';
import {v4 as uuidV4} from 'uuid';
import {Log} from '@devanjs/log';

import {overlays} from '@teemill/modules/overlays';

import PageContainer from '../components/PageContainer.vue';

import {
  standardPageAccess,
  isLoggedIn,
  isLoggedOut,
  canVisitUnpublishedPages,
  hasDashboardAccess,
} from '../../store-front/access';

import {Theme, ThemeLike} from '../../theme-builder';

import {pageBus} from '../bus';

import {PageProvider} from '../providers/pageProvider';

import {
  PageNotFoundError,
  PagePermissionDeniedError,
  PageServerError,
  PageValidationError,
} from '../errors/pageErrors';

import {Block} from './block';
import {PageObject} from './pageObject';
import {Property, PropertySet} from './property';
import {UnregisteredBlockTypeError} from './blockType';
import {IconDefinition} from '@fortawesome/pro-light-svg-icons';
import {AccessStatement} from '@teemill/common/classes';
import {markRaw} from 'vue';

/**
 * * Used to store un-reactive fetch statuses.
 * ? If the status is stored on the page object, a fetch loop will be
 * ? created if the page object is made reactive.
 */
const fetchStatuses: Record<number, boolean> = {};

type PageMode = 'native' | 'preview' | 'unknown';

export type PageFlags = Set<string>;

export interface PageLike {
  id?: number;
  uuid?: string;
  url?: string;
  layout: string;
  blocks: Block[];
  flags?: PageFlags | string[];
  properties: PropertySet;
  preloadables: PagePreloadable[];
  version?: string;
  published: boolean;
  sitemap: boolean;
  keyPhraseCampaignId?: number;
  edited?: boolean;
  divisionId?: number;
  mode?: PageMode;
  theme?: ThemeLike;
}

export interface PagePreloadable {
  type: string;
  url: string;
}

export class Page extends PageObject<void> {
  public id?: number;
  public uuid: string;
  public url?: string;
  public layout: string;
  public blocks: Block[];
  public flags: PageFlags;
  public properties: PropertySet;
  public preloadables: PagePreloadable[];
  public version?: string;

  public published: boolean;
  public sitemap: boolean;
  public keyPhraseCampaignId?: number;
  public edited?: boolean;
  public isV1Page?: boolean;
  public divisionId?: number;

  public isSaving = false;
  public isDeleting = false;

  public mode = 'unknown';

  constructor({
    id,
    uuid,
    url,
    layout = 'standard',
    blocks = [],
    flags = [],
    properties = {
      isStorePage: Property.from(0),
      isMailPage: Property.from(0),
      loginState: Property.from('everyone'),
    },
    preloadables = [],
    version,
    divisionId,
    published = true,
    sitemap = true,
    edited,
    keyPhraseCampaignId = undefined,
    mode = 'unknown',
    theme,
  }: PageLike) {
    super();

    this.id = id;
    this.uuid = uuid || uuidV4();
    this.url = url;
    this.layout = layout;
    this.flags = new Set(flags);
    this.properties = Property.map(properties);
    this.preloadables = preloadables;
    this.version = version;
    this.edited = edited;

    this.published = published;
    this.keyPhraseCampaignId = keyPhraseCampaignId;
    this.sitemap = sitemap;
    this.mode = mode;

    this.divisionId =
      divisionId ||
      // @ts-expect-error Vuex ts conversion needed
      $store.state.store?.active?.id ||
      // @ts-expect-error Vuex ts conversion needed
      $store.state.subdomain?.division;

    this.blocks = blocks
      .sort((a, b) => a.order - b.order)
      .map((block, index) => {
        if (block instanceof Block) {
          return block.setOrder(index).assignTo(this);
        }

        try {
          return new Block(block).setOrder(index).assignTo(this);
        } catch (error: any) {
          switch (error.constructor) {
            case UnregisteredBlockTypeError:
              return undefined;

            default:
              throw error;
          }
        }
      })
      .filter(b => b !== undefined) as Block[];

    // @deprecated
    this.isV1Page =
      !!this.blocks.length &&
      this.blocks.some(b => !b.property('template') && !b.flags.has('deleted'));

    this.overrideTheme = theme ? new Theme(theme) : undefined;

    Log.tag('pages-v')
      .orange('Type')
      .text('Created Page')
      .lightBlue(this.id, this.uuid)
      .lightGreen(this.blocks.length)
      .info();
  }

  public get exists() {
    return this.id !== undefined;
  }

  public get isHomepage() {
    return this.flags.has('homepage');
  }

  public get deletable() {
    if (!this.exists) {
      return false;
    }

    return !this.isHomepage;
  }

  /**
   * Gets a formatted version of a Page object that only contains
   * values relevant to user input.
   */
  public get state() {
    const clone = this.copy();
    clone.overrideTheme = undefined;
    clone.preloadables = [];

    clone.published = Boolean(clone.published);
    clone.sitemap = Boolean(clone.sitemap);
    clone.keyPhraseCampaignId = clone.keyPhraseCampaignId || undefined;

    clone.properties = Property.map(
      clone.properties,
      p => new Property({value: p.get()})
    );

    if (!clone.property('loginState')) {
      clone.setProperty('loginState', 'everyone');
    }

    // ignores blocks that have been added then deleted
    clone.blocks = clone.blocks
      .filter(b => b.id || !b.flags.has('deleted'))
      .sort((a, b) => a.order - b.order)
      .map((b, index) => b.state.setOrder(index));

    return clone;
  }

  public get loginStateAccessStatement():
    | AccessStatement
    | AccessStatement[]
    | undefined {
    if (this.property('loginState') === 'logged-in') {
      return isLoggedIn;
    } else if (this.property('loginState') === 'logged-out') {
      return isLoggedOut;
    } else if (this.property('loginState') === 'dashboard-access') {
      return hasDashboardAccess;
    }

    return undefined;
  }

  public get searchIcon() {
    const searchIcon = this.property(
      'searchIcon',
      'json',
      undefined
    ) as IconDefinition;
    return searchIcon?.iconName;
  }

  public get routeDef(): RouteRecordRaw {
    return {
      path: this.url || uniqueId(),
      name:
        this.url === '/'
          ? 'home'
          : this.url?.replace(/^\/|\/$/g, '').replace(/\//, '.'),
      meta: {
        id: this.id,
        title: this.property('title'),
        access: [
          standardPageAccess,
          this.loginStateAccessStatement,
          !this.published ? canVisitUnpublishedPages : null,
        ],
        isStoreFront: true,
        layout: this.layout,
        layoutOverrides: {
          showGlobal: this.mode === 'preview' ? false : undefined,
        },
        primaryImage: sample(
          this.preloadables.filter(preloadable => preloadable.type === 'image')
        ),
        showOnSiteMap: this.published && this.sitemap,
        search: {
          show: this.property('isSearchable', 'boolean'),
          icon: this.searchIcon,
        },
        /**
         * Allow PWA ServiceWorker to reload the window when navigation to the page is triggered
         * @see @teemill/modules/app/usePwaRefresh
         */
        allowPwaReload: true,
      },
      component: markRaw(PageContainer),
      props: {
        pageId: this.id,
      },
      beforeEnter: (to, from, next) => {
        this.fetch();
        return next();
      },

      // prefetchChunks: [
      //   'TmlPage',
      //   'TmlEssentials',
      //   'TmlFrontend',
      //   'TmlSlideShow',
      //   'TmlPages',
      // ],
    };
  }

  public async fetch() {
    if (!this.id) {
      pageBus.emit('page-loaded');

      return;
    }

    if (fetchStatuses[this.id]) {
      pageBus.emit('page-loaded');

      return;
    }

    fetchStatuses[this.id] = true;

    try {
      if (!this.divisionId) {
        throw new PageValidationError('Page not attached to a store');
      }

      const page = await PageProvider.get(this.id, this.divisionId);

      if (page.version !== this.version) {
        this.updateFrom(page);
      }

      pageBus.emit('page-loaded');
    } catch (error: any) {
      if (this.mode === 'native') {
        pageBus.emit('page-not-found');

        return;
      }

      switch (error.constructor) {
        default:
        case PageServerError:
          snackbar.error(
            'Oops... Looks like something went wrong loading this page'
          );
          break;
        case PagePermissionDeniedError:
          snackbar.error(
            "Oops... Looks like you're trying to access a page from another store"
          );
          break;
        case PageNotFoundError:
          snackbar.error('We were unable to find this page');
          break;
      }
    } finally {
      /**
       * ? Do not try to refetch the page for at least 30 seconds.
       * ? Avoids always sending requests if the user is quickly
       * ? browsing the site.
       */
      setTimeout(() => {
        fetchStatuses[this.id || 0] = false;
      }, 30000);
    }
  }

  public toObject() {
    const {
      id,
      uuid,
      url,
      layout,
      flags,
      properties,
      published,
      edited,
      sitemap,
      keyPhraseCampaignId,
      divisionId,
      mode,
      theme,
    } = this;

    const blocks = this.blocks
      .filter(block => block.type)
      .map(block => block.toObject());

    return {
      id,
      uuid,
      url,
      layout,
      flags: Array.from(flags),
      properties: Property.map<Property>(properties, p => p.toObject()),
      blocks,
      published,
      edited,
      sitemap,
      keyPhraseCampaignId,
      divisionId,
      mode,
      theme: theme?.toObject(),
    };
  }

  public copy(): Page {
    return new Page(cloneDeep(this) as PageLike);
  }

  public setMode(mode: string): Page {
    this.mode = mode;

    return this;
  }

  public updateFrom(page: Page): void {
    const uuid = this.uuid;
    const mode = this.mode;
    const parent = this.parent;

    Object.assign(this, page).setMode(mode).assignTo(parent);

    this.uuid = uuid;

    this.blocks.forEach(block => {
      block.assignTo(this);
    });
  }

  public async save() {
    this.isSaving = true;

    try {
      if (!this.divisionId) {
        throw new PageValidationError('Page not attached to a store');
      }

      this.updateFrom(await PageProvider.save(this, this.divisionId));

      // @ts-expect-error Vuex ts conversion needed
      const completion = $store.state.store.completion as {
        currentStep: string;
        steps: {
          index: string;
          complete: boolean;
        }[];
      };

      if (completion) {
        const step = completion.steps.find(step => step.index === 'homepage');

        if (step) {
          step.complete = true;
          completion.currentStep = 'collection';
        }
      }

      return this;
    } finally {
      this.isSaving = false;
    }
  }

  public async delete(): Promise<boolean> {
    if (!this.deletable) {
      return false;
    }

    const confirmed = await overlays.confirm(
      'Delete page',
      'Are you sure you want to delete this page?',
      {
        confirm: 'Delete page',
        cancel: 'Cancel',
      }
    );

    if (!confirmed) {
      return false;
    }

    this.isDeleting = true;

    try {
      if (!this.divisionId) {
        throw new PageValidationError('Page not attached to a store');
      }

      await PageProvider.delete(this, this.divisionId);
    } finally {
      this.isDeleting = false;
    }

    return true;
  }

  public assignTo(object: any): Page {
    this.parent = object;

    return this;
  }

  public get temporaryUrl() {
    return this.uuid.substring(0, 8);
  }
}
